import React from "react";
import Head from "next/head";
import { SessionProvider } from "next-auth/react";
import dynamic from "next/dynamic";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { QueryClientProvider, QueryClient } from "react-query";
import * as Sentry from "@sentry/node";
import { Hydrate } from "react-query/hydration";
import { createLoopTheme } from "@loop-brasil/material";
import "global.css";

const DynamicPolicyCookies = dynamic(() => import("components/PolicyCookies"), {
  ssr: false
});

const DynamicReferralContact = dynamic(
  () => import("components/ReferralContact"),
  { ssr: false }
);

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV
  });
}

const themeLoop = createLoopTheme();

const HALF_HOUR = 60 * 30;

const LoopSantander = ({
  Component,
  pageProps: { session, ...pageProps },
  err
}) => {
  const queryClientRef = React.useRef();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false
        }
      }
    });
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Loop Veículos" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.loopveiculos.com.br" />
        <meta
          property="og:image"
          content="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/loopbrasil/b/assets/o/revenda/logo-revenda-santander.png"
        />
        <meta property="og:site_name" content="Loop Veículos" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <QueryClientProvider client={queryClientRef.current}>
        <ThemeProvider theme={themeLoop}>
          <CssBaseline />
          <SessionProvider
            session={session}
            refetchOnWindowFocus={false}
            refetchInterval={HALF_HOUR}
          >
            <Hydrate state={pageProps.dehydratedState}>
              <Component {...pageProps} err={err} />
            </Hydrate>
            <DynamicPolicyCookies />
            <DynamicReferralContact />
          </SessionProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

export default LoopSantander;
